function colorWithAlpha(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r},${g},${b},${alpha})`;
}

// General colors by name
const GeneralColors = {
    surfie: '#0A8577',
    surfieLight: '#22B7A6',
    sea: '#1EA8A5',
    eclipse: '#404040',
    slate: '#777777',
    gainsboro: '#D9D9D9',
    cloud: '#FAFAFA',
    snow: '#FFFFFF',
    punch: '#9D3535',
    jaffa: '#DD7B46',
    smoke: '#F0F0F0',
    shadow: '#000000',
    ivory: '#FFFEF1',
    pearl: '#FEFBE8',
};

const CommonColors = {
    ...GeneralColors,

    // Colors based on usage
    checkbox: GeneralColors.surfie,
    disabled: GeneralColors.gainsboro,
    button: GeneralColors.surfie,
    disabledButton: GeneralColors.gainsboro,
    notedText: GeneralColors.surfie,
    darkText: GeneralColors.eclipse,
    lightSpinner: GeneralColors.gainsboro,
    darkSpinner: GeneralColors.surfie,
    warningText: GeneralColors.punch,
    lateText: GeneralColors.punch,
    sectionBorder: GeneralColors.gainsboro,
    searchBox: GeneralColors.gainsboro,
    activeTab: GeneralColors.surfie,
    inactiveTab: GeneralColors.eclipse,
    syncedText: GeneralColors.slate,
    qboSelected: GeneralColors.surfie,
    overdraft: colorWithAlpha(GeneralColors.punch, 0.15),
    listSeperator: GeneralColors.gainsboro,
    tabUnselected: GeneralColors.gainsboro,
    invoiceMessageBorder: GeneralColors.smoke,
    buttonShadow: GeneralColors.shadow,
    buttonSubmittingPrimary: colorWithAlpha(GeneralColors.surfie, 0.5),
    buttonSubmittingSecondary: colorWithAlpha(GeneralColors.snow, 0.5),
    checkboxEnabledWrapper: GeneralColors.eclipse,
    modalDark: GeneralColors.shadow,
    discountedText: GeneralColors.sea,
    circleBorder: colorWithAlpha(GeneralColors.surfie, 0.15),
    alertBoxBorder: colorWithAlpha(GeneralColors.jaffa, 0.4),
    alertBoxBackground: colorWithAlpha(GeneralColors.jaffa, 0.1),
};

export default CommonColors;
