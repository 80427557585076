import { lazy, Suspense, useEffect, useState } from 'react';
import useActiveUser from 'app/hooks/useActiveUser';
import * as FeatureFlag from 'app/utils/FeatureFlag';
import useBreakpoints from 'app/hooks/useBreakpoints';
import { isObject } from 'app/utils/Utils';

const AdminContainer = lazy(() => import(/* webpackChunkName:"internal" */'./ControlHelpContainer'));

export default function ControlHelpPortal () {
    const { activeUser } = useActiveUser();
    const { isDesktopWeb } = useBreakpoints();
    const [ isVisible, setIsVisible ] = useState(FeatureFlag.get(FeatureFlag.FEATURE.CONTROL_HELP));

    useEffect(() => {
        const listener = async (event) => {
            if (event.ctrlKey && event.shiftKey && event.key === 'H') {
                const flag = FeatureFlag.get(FeatureFlag.FEATURE.CONTROL_HELP);
                await FeatureFlag.store(FeatureFlag.FEATURE.CONTROL_HELP, !flag);
            }
        };

        if (activeUser?.isSuperUser) {
            document.addEventListener('keydown', listener);
        }

        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [activeUser, isVisible]);

    useEffect(() => {
        if (activeUser?.isSuperUser) {
            const handleStorage = () => {
                const flag = FeatureFlag.get(FeatureFlag.FEATURE.CONTROL_HELP);
                if (isObject(flag)) {
                    setIsVisible(flag.visible);
                } else {
                    setIsVisible(flag);
                }
            };

            window.addEventListener('storage', handleStorage);
            return () => window.removeEventListener('storage', handleStorage);
        }
    }, [activeUser]);

    if (isDesktopWeb && activeUser?.isSuperUser && isVisible) {
        return (
            <Suspense>
                <AdminContainer />
            </Suspense>
        );
    }
}
