import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useActiveCompany from 'app/hooks/useActiveCompany';
import TopNavBar from 'app/components/nav/TopNavBar';
import Header from 'app/components/nav/Header';
import FixedFooter from './FixedFooter';
import styles from './Screen.module.scss';

export default function Screen({
    fixedFooter,
    children,
    backButtonFallback,
    title,
    description,
    logoUri,
    logoSize,
    actionItems,
    header,
    footer,
    isCloseable,
    isTopLevel,
    documentTitle,
    onClose,
}) {
    const { activeCompany, FEATURES } = useActiveCompany();

    useEffect(() => {
        const parts = ['Wholesail'];
        if (activeCompany?.displayName) {
            parts.unshift(activeCompany?.displayName);
        }
        if (documentTitle) {
            parts.unshift(documentTitle);
        }
        document.title = parts.join(' | ');
    }, [documentTitle, activeCompany]);

    const cobrandingLogo = activeCompany?.feature(FEATURES.cobrandingLogo);

    return (
        <React.Fragment>
            <TopNavBar
                backButtonFallback={backButtonFallback || undefined}
                className={cx(styles.headerSpacing, styles.topNavBar)}
                isCloseable={isCloseable}
                onClose={onClose}
            >
                {actionItems}
            </TopNavBar>
            <Header
                title={title}
                description={description}
                logoUri={logoUri}
                logoSize={logoSize}
                className={styles.headerSpacing}
                isTopLevel={isTopLevel}
            />
            {header}
            {children}
            {footer}
            {cobrandingLogo && <img
                src={cobrandingLogo}
                className={styles.cobrandingLogo}
            />}
            <FixedFooter>
                {fixedFooter}
            </FixedFooter>
        </React.Fragment>
    );
}

Screen.defaultProps = {
    fixedFooter: null,
    header: null,
    isCloseable: false,
    footer: null,
    documentTitle: null,
    logoSize: 'small',
};

Screen.propTypes = {
    children: PropTypes.node,
    documentTitle: PropTypes.string,
    fixedFooter: PropTypes.node,
    header: PropTypes.node,
    isCloseable: PropTypes.bool,
    footer: PropTypes.node,
    logoSize: PropTypes.oneOf(['small', 'large']),
    onClose: PropTypes.func,

    ...Header.propTypes,

    // undefined means no backButton
    backButtonFallback: PropTypes.object,
    actionItems: TopNavBar.propTypes.children,
};
