import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Navigation from 'app/platform/Navigation';
import Router from 'app/utils/Router';
import { setInstance } from 'app/platform/Router';
import Url from 'app/platform/Url';
import useActiveUser from 'app/hooks/useActiveUser';

export const NavigationContext = React.createContext({});

const { host, protocol } = window.location;
const origin = `${protocol}//${host}`;

function NavigationProvider ({ children }) {
    const [navState, _setNavState] = useState();
    const [router, setRouter] = useState();
    const [error, setError] = useState();
    const { activeUser } = useActiveUser();

    const setNavState = useCallback((state) => {
        _setNavState(state);

        // Reset navigation errors now that we have a new navState
        setError(undefined);
    }, [_setNavState, setError]);

    const navigation = useMemo(() => {
        const nav = router && {
            'navigate': router.navigate,
            'replace': router.replace,
            'goBack': router.goBack,
            'state': navState,
            'setParams': router.setParams,
            'push': router.push,
        };

        // Update legacy way
        Navigation.updateNavigation(nav);
        return nav;
    }, [navState, router]);

    useEffect(() => {
        const router = new Router({
            origin,
            onError: setError,
            onNavigation: setNavState,
            parseQueryString: Url.parseQueryString,
        });
        // The old way
        setInstance(router);
        // The new way
        setRouter(router);
    }, []);

    useEffect(() => {
        router?.setIsSuperAdmin(activeUser?.isSuperUser);
    }, [activeUser?.isSuperUser, router]);

    // If there is a session token in the URL, wait until Navigation sets it and replaces
    const isNavigationReady = !!navigation?.state;

    return (
        <NavigationContext.Provider value={{ navigation, router, error }}>
            {isNavigationReady && children}
        </NavigationContext.Provider>
    );
}

NavigationProvider.propTypes = {
    children: PropTypes.node,
};

export default NavigationProvider;
