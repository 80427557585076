import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import useActiveUser from 'app/hooks/useActiveUser';
import useNavigation from 'app/hooks/useNavigation';
import useBuyerOnboarding from 'app/hooks/network/useBuyerOnboarding';
import { OMIT, SECTION_IDS } from 'app/constants/Onboarding';

import { getSections, validationCodes } from './utils';
import useAccountsQuery from 'app/hooks/network/useAccountsQuery';

export const CustomerFormContext = createContext({});

const ERROR_POINTER_LIST = [
    {
        pointer: '/tradeRefs',
        section: SECTION_IDS.tradeRefs,
    },
    {
        pointer: '/additionalUsers',
        section: SECTION_IDS.company,
    },
    {
        pointer: '/bankReport',
        section: SECTION_IDS.bankReport,
    },
    {
        pointer: '/paymentAccounts',
        section: SECTION_IDS.paymentAccounts,
    },
    {
        pointer: '/paymentPlan',
        section: SECTION_IDS.paymentAccounts,
    },
    {
        pointer: '/company',
        section: SECTION_IDS.company,
    },
];

function getSectionForError (error, sections) {
    const errorPointer = error.json()?.detail?.[0]?.pointer;

    if (errorPointer) {
        const pointerSection = ERROR_POINTER_LIST.find(p => errorPointer.startsWith(p.pointer));
        if (pointerSection) {
            return {
                section: sections.find(section => section.name === pointerSection.section),
                error: error.json().detail[0],
            };
        }
    }
}

function shouldUpgradeSession (activeUserId, accountsData = {}, formData = {}) {
    const restrictedAccountIds = accountsData.capabilities?.[activeUserId]?.hiddenByRestrictedSession || [];
    if (restrictedAccountIds.length === 0) {
        return false;
    }

    const formPaymentAccounts = [
        formData.paymentAccounts?.fields?.ach?.account?.id,
        formData.paymentAccounts?.fields?.cc?.account?.id,
    ].filter(Boolean);

    return restrictedAccountIds.filter(accountId => !formPaymentAccounts.includes(accountId)).length > 0;
}

export default function CustomerFormProvider({ children }) {
    const {
        previewMode,
        companySlug,
        applicationSlug,
        applicationId,
        sellerData,
        config,
        formData,
        formArchived,
        formPrimaryUserId,
        formState,
        isLoading,
        mutation,
        stripeAccountMutation,
        removeBankReportAccountMutation,
        reuseBankReportAccountForPaymentMutation,
        removePaymentAccountMutation,
        updatePaymentAccountMutation,
        agreeToPaymentPlanMutation,
        linkPaymentAccountMutation,
        submitMutation,
        refetchApplication,
        getSensitiveField,
    } = useBuyerOnboarding();

    const { navigate, state: navState, replace } = useNavigation();
    const startingSection = navState?.params?.section;

    let availableSections = useMemo(() => {
        if (config) {
            return getSections(config, sellerData);
        }
        return [];
    }, [config, sellerData]);

    const [activeSection, setActiveSection] = useState();
    const [error, setError] = useState();
    const [ isSectionInitialized, setIsSectionInitialized ] = useState(false);
    const { activeUser, activeUserId, isLoading: activeUserLoading } = useActiveUser();
    const { data: accountsData, refetch: refetchPaymentAccounts } = useAccountsQuery({ action: 'OWNER' });

    const existingPaymentAccounts = useMemo(() => {
        return accountsData && Object.values(accountsData.paymentAccounts);
    }, [accountsData]);
    const upgradeForPaymentAccounts = shouldUpgradeSession(activeUserId, accountsData, formData);

    useEffect(() => {
        if (startingSection && !isLoading) {
            setActiveSection(availableSections.find(sec => sec.name === startingSection));
            setIsSectionInitialized(true);
            const newParams = { ...navState.params };
            delete newParams.section;

            replace(navState.routeName, newParams);
        }
    }, [startingSection, isLoading, availableSections, replace, navState.routeName, navState.params]);

    useEffect(() => {
        if (availableSections && formState === 'SUBMITTED' && !startingSection) {
            setActiveSection(availableSections[availableSections.length - 1]);
        }
    }, [formState, availableSections, startingSection]);

    useEffect(() => {
        if (previewMode && !activeUserLoading) {
            if (!activeUser) {
                navigate('OnboardingLanding', navState);
            }
        }
    }, [previewMode, activeUser, activeUserLoading, navigate, navState]);

    useEffect(() => {
        if (isSectionInitialized) {
            return;
        }
        if (!startingSection && !isLoading && config && availableSections && formData) {
            if (formState === 'SUBMITTED') {
                setActiveSection(availableSections[availableSections.length - 1]);
            } else {
                let sectionIdToGoTo = SECTION_IDS.primaryUser;
                if (config.paymentAccounts.req !== OMIT && formData.paymentAccounts) {
                    sectionIdToGoTo = SECTION_IDS.paymentAccounts;
                } else if (config.bankReport.req !== OMIT && formData.bankReport) {
                    sectionIdToGoTo = SECTION_IDS.bankReport;
                } else if (config.tradeRefs.req !== OMIT && formData.tradRefs?.length > 0) {
                    sectionIdToGoTo = SECTION_IDS.tradeRefs;
                } else if (formData.company) {
                    sectionIdToGoTo = SECTION_IDS.company;
                }
                const sectionToGoTo = availableSections.find(av => av.name === sectionIdToGoTo);
                if (sectionToGoTo) {
                    setActiveSection(sectionToGoTo);
                } else {
                    setActiveSection(availableSections[0]);
                }

            }

            setIsSectionInitialized(true);
        }
    }, [isSectionInitialized, isLoading, config, availableSections, formData, formState, startingSection]);

    const goToSection = useCallback((section) => {
        setActiveSection(section);
    }, []);

    const goToNextSection = useCallback(() => {
        setActiveSection(availableSections[activeSection.index + 1]);
    }, [activeSection, setActiveSection, availableSections]);

    const progress = activeSection ? (activeSection.index / (availableSections.length - 1)) * 100 : 0;

    availableSections = useMemo(() => {
        return availableSections?.map(section => ({
            ...section,
            complete: section.index < activeSection?.index,
            current: section.name === activeSection?.name,
        }));
    }, [availableSections, activeSection]);

    const isLastSection = activeSection?.index === availableSections?.length - 2;

    const mutate = useCallback(async (variables, callbacks) => {
        if (previewMode) {
            return setActiveSection(availableSections[activeSection.index + 1]);
        }

        mutation.mutate(variables, {
            onMutate: () => {
                setError(undefined);
            },
            onSuccess: () => {
                if (isLastSection) {
                    submitForm({});
                } else {
                    setActiveSection(availableSections[activeSection.index + 1]);
                }
            },
            onError: (error, variables) => {
                const errors = {};
                if (error.isError('BadRequestException', 'json_processing')) {
                    // TODO: this can be removed once BE has been updated to new "bad_phone" error below
                    if (error.json().message.indexOf('invalid phone number')) {
                        if (variables.tradeRefs || variables.additionalUsers) {
                            try {
                                const index = Number.parseInt(
                                    error.json().message.match(/java\.util\.ArrayList\[(\d+)\]/)?.[1],
                                    10
                                );
                                if (variables.tradeRefs) {
                                    errors.tradeRefs = errors.tradeRefs || [];
                                    errors.tradeRefs[index] = { phoneNumber: validationCodes.invalidPhone };
                                } else if (variables.additionalUsers) {
                                    errors.additionalUsers = errors.additionalUsers || [];
                                    errors.additionalUsers[index] = { phoneNumber: validationCodes.invalidPhone };
                                }
                            } catch (e) {
                                // ignore
                            }
                        } else if (variables.primaryUser) {
                            errors.phoneNumber = validationCodes.invalidPhone;
                        }
                    }
                } else if (error.isError('BadRequestException', 'bad_phone')) {
                    const invalidPhoneNumber = error.json().detail?.phone;
                    if (invalidPhoneNumber) {
                        if (variables.additionalUsers) {
                            const index = variables.additionalUsers.findIndex(
                                u => u.phoneNumber === invalidPhoneNumber
                            );
                            errors.additionalUsers = errors.additionalUsers || [];
                            errors.additionalUsers[index] = { phoneNumber: validationCodes.invalidPhone };
                        } else if (variables.tradeRefs) {
                            const index = variables.tradeRefs.findIndex(u => u.phoneNumber === invalidPhoneNumber);
                            errors.tradeRefs = errors.tradeRefs || [];
                            errors.tradeRefs[index] = { phoneNumber: validationCodes.invalidPhone };
                        } else if (variables.primaryUser) {
                            errors.phoneNumber = validationCodes.invalidPhone;
                        }
                    }
                } else if (error.isError('BadRequestException', 'onboarding_validation')) {
                    try {
                        const errorField = error.json().detail[0].pointer.slice(1).split('/')[1];
                        errors[errorField] = error.json().detail[0].type;
                    } catch (e) {
                        // Do nothing if we can't figure out what the error was
                    }
                }

                callbacks.onError && callbacks.onError(errors);
            },
        });
    }, [mutation, setActiveSection, activeSection, availableSections, submitForm, isLastSection, previewMode]);

    const submitForm = useCallback(async (variables) => {
        if (previewMode) {
            return setActiveSection(availableSections[activeSection.index + 1]);
        }
        submitMutation.mutate(variables, {
            onMutate: () => {
                setError(undefined);
            },
            onSuccess: () => {
                setActiveSection(availableSections[activeSection.index + 1]);
            },
            onError: (error) => {
                if (error.isError('BadRequestException', 'onboarding_validation')) {
                    const { section, error: sectionError } = getSectionForError(error, availableSections);
                    if (section) {
                        setError(sectionError);
                        setActiveSection(section);
                    }
                }
            },
        });
    }, [submitMutation, availableSections, activeSection, previewMode]);

    const refetchData = async () => {
        refetchApplication();
        refetchPaymentAccounts();
    };

    const value = {
        previewMode,
        companySlug,
        applicationSlug,
        applicationId,
        existingPaymentAccounts,
        upgradeForPaymentAccounts,
        refetchApplication: refetchData,
        sellerData,
        config,
        error,
        formData,
        formArchived,
        formPrimaryUserId,
        formState,
        progress,
        activeSection,
        availableSections,
        isLastSection,
        goToSection,
        goToNextSection,
        isLoading: isLoading || !sellerData || !activeSection,
        mutation: {
            ...mutation,
            mutate: mutate,
        },
        addStripeAccount: stripeAccountMutation.mutateAsync,
        removeBankReportAccount: removeBankReportAccountMutation.mutateAsync,
        reuseBankReportAccountForPayment: reuseBankReportAccountForPaymentMutation.mutateAsync,
        removePaymentAccount: removePaymentAccountMutation.mutateAsync,
        updatePaymentAccount: updatePaymentAccountMutation.mutateAsync,
        linkPaymentAccount: linkPaymentAccountMutation.mutateAsync,
        agreeToPaymentPlan: agreeToPaymentPlanMutation.mutateAsync,
        submitFormMutation: {
            ...submitMutation,
            mutateAsync: submitForm,
        },
        getSensitiveField,
    };

    return (
        <CustomerFormContext.Provider value={value}>
            {children}
        </CustomerFormContext.Provider>
    );
}
