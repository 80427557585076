
export const PLACEHOLDER = '\u2014';/* JS strings do not support entity names */
export const NOOP = () => {};

export const withPlaceholder = (
    field: any,
    formatter: (val: any) => any
) => {
    return field ? formatter(field) : PLACEHOLDER;
};

export const strToDate = (date: string): Date | undefined => {
    if (!date) {
        return undefined;
    }

    const fullDate = date.indexOf('T') > 1 ? date : `${date}T00:00:00Z`;
    return new Date(fullDate);
};

export function randomIdOfLength (length: number) {
    let id = '';
    while (id.length < length) {
        id += Math.random().toString(16).substring(2);
    }
    return id.substr(0, length);
}

export const truncate = (input: string, max: number) => input.length > max ?
    `${input.substring(0, max)}...` :
    input;

export const centsToDollars = (input: number): number => {
    const isNegative = input < 0;
    // Drop fractional cents off
    const centsStr = Math.abs(Math.floor(input || 0)).toFixed(0).padStart(2, '0');
    const dollarStr = centsStr.substring(0, centsStr.length - 2) + '.' + centsStr.substring(centsStr.length - 2, centsStr.length);
    const multiplier = isNegative ? -1 : 1;

    return parseFloat(dollarStr) * multiplier;
};

export const addressLines = (address: any = {}): Array<string> => {
    return [ ...(address?.lines || []), territoryFromAddress(address)].filter(Boolean);
};

export const territoryFromAddress = (address: any = {}) => {
    const territory = [address.city, address.subdivision].filter(Boolean).join(', ');
    return [territory, address.postCode].filter(Boolean).join(' ');
};

export const capitalize = (str: string, opts = { lowerCaseRemaining: true }) => {
    const remaining = (remStr: string) => opts.lowerCaseRemaining ? remStr.toLowerCase() : remStr;
    return str && str.length > 0 ?
        str[0]?.toUpperCase() + remaining(str.substring(1)) :
        str;
};

export function relationshipTitle (seller: any, buyer: any): string | undefined {
    if (buyer && buyer.displayName && seller && seller.displayName) {
        return `${seller.displayName}  \u21C4  ${buyer.displayName}`;
    }
}

export function formatBasisPoints (basisPoints: number) {
    return Math.abs(basisPoints / 100) + '%';
}

export const trimPoNumber = (poNumber: string): string => {
    let trimmed = poNumber;
    if (poNumber?.indexOf('PO #') === 0) {
        return poNumber.substring(4).trim();
    } else if (poNumber?.indexOf('PO#') === 0){
        trimmed = poNumber.substring(3).trim();
    } else if (poNumber?.indexOf('#') === 0) {
        trimmed = poNumber.substring(1);
    } else if (poNumber?.indexOf('PO ') === 0) {
        trimmed = poNumber.substring(3);
    }

    return trimmed;
};

